import React from "react";

export default function PlayerOverlay({ data }) {
  return (
    <div className={`overlay-video`}>
      <div className="overlay-container">
        <h1 className='overlay-h1'>
          <div dangerouslySetInnerHTML={{ __html: data?.VideoPlayer?.title }} />
        </h1>
        <p className="overlay-p">{data.VideoPlayer.subTitle}</p>
      </div>
    </div>

  );
}
