import React, { useEffect, useState, useRef } from "react";
import apiEndpoint from "../../config/config";
import WidgetPlayer from "../WidgetPlayer/WidgetPlayer";
import PlayerOverlay from "./PlayerOverlay";

const Player = (props: any) => {
  const windowSize = useWindowSize();
  const [mute, setMute] = useState(true);
  const ref = useRef(null);

  function useWindowSize() {
    const [dimension, setDimensions] = useState({
      height: undefined,
      width: undefined,
    });

    useEffect(() => {
      const handleResize = () => {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      };

      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return dimension;
  }

  const [Videostate, setVideostate] = useState({
    isClosed: true,
    videoUrl: props.data.VideoPlayer
      ? props.data.VideoPlayer.external
        ? props.data.VideoPlayer.url
        : apiEndpoint.baseUrl + props.data.VideoPlayer.video.url
      : "",
  });

  const onTogglerHandler = () => {
    setVideostate({ ...Videostate, isClosed: !Videostate.isClosed });
  };

  const handleMute = () => {
    var video = ref.current;
    if (mute) {
      video.muted = false;
    } else {
      video.muted = true;
    }
    setMute((prev) => !prev);
  };

  if (props.type) {
    return (
      props.data.VideoPlayer && (
        <React.Fragment>
          <div className="playermain">
            <div
              className={
                windowSize.width >= 768 ? "show show-relative" : "hide"
              }
            >
              <video autoPlay muted loop playsInline className="player">
                <source
                  src={
                    props.data.VideoPlayer.external
                      ? props.data.VideoPlayer.url
                      : apiEndpoint.baseUrl + props.data.VideoPlayer.video.url
                  }
                  type="video/mp4"
                />
                {/* <track
                  src="captions_en.vtt"
                  kind="captions"
                  srcLang="en"
                  label="english_captions"
                ></track> */}
              </video>
              {props.landing && (
                <PlayerOverlay data={props.data} />
              )}
            </div>
          </div>
          <div className="playermain">
            <div
              className={windowSize.width < 768 ? "show show-relative" : "hide"}
            >
              <video autoPlay muted loop playsInline className="player" ref={ref}>
                <source
                  src={
                    props.data.VideoPlayer.external
                      ? props.data.VideoPlayer.url
                      : props.data.VideoPlayer.mobileVideo
                      ? apiEndpoint.baseUrl +
                        props.data.VideoPlayer.mobileVideo.url
                      : ""
                  }
                  type="video/mp4"
                />
                {/* <track
                  src="captions_en.vtt"
                  kind="captions"
                  srcLang="en"
                  label="english_captions"
                ></track> */}
              </video>
              {props.landing && (
                <PlayerOverlay data={props.data} />
              )}
              {/* {mute ? (
                <div className="show-mute-button" onClick={handleMute}>
                  <i
                    className="fas fa-volume-mute fa-lg"
                    aria-hidden="true"
                  ></i>
                </div>
              ) : (
                <div className="show-mute-button" onClick={handleMute}>
                  <i className="fas fa-volume-up fa-lg" aria-hidden="true"></i>
                </div>
              )} */}
            </div>
          </div>
        </React.Fragment>
      )
    );
  } else {
    return (
      <div className="image-video-player">
        {windowSize.width > 768 && (
          <video autoPlay muted loop className="player">
            <source
              src={apiEndpoint.baseUrl + props.data.backgroundVideo.url}
              type="video/mp4"
            />
            {/* <track
              src="captions_en.vtt"
              kind="captions"
              srcLang="en"
              label="english_captions"
            ></track> */}
          </video>
        )}
        {windowSize.width <= 768 && (
          <div
            className="player img"
            style={{
              backgroundImage: `url(${
                props.data.backgroundImage
                  ? apiEndpoint.baseUrl + props.data.backgroundImage.url
                  : ""
              }`,
            }}
          />
        )}
        <div className="overlay" />
        <div className="content">
          <h1>{props.data.title}</h1>
          <h2>{props.data.subTitle}</h2>
          <p onClick={onTogglerHandler}>
            {props.data.WatchVideoLink.text}
            <i
              className={props.data.WatchVideoLink.icon + " playerIcon"}
              style={{ color: "#fff" }}
              aria-hidden="true"
            />
          </p>
          <a href={props.data.BuyNowButton.url} className="button">
            {props.data.BuyNowButton.text}
          </a>
        </div>
        {!Videostate.isClosed ? (
          <WidgetPlayer
            videoUrl={apiEndpoint.baseUrl + props.data.popupVideo.url}
            onCloseHandler={onTogglerHandler}
            closePosition={true}
          />
        ) : null}
      </div>
    );
  }
};

export default Player;
